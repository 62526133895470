import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ordering-and-download"
    }}>{`Ordering and Download`}</h1>
    <h2 {...{
      "id": "overview"
    }}>{`Overview`}</h2>
    <p>{`Once you have results returned from a search, the next step in accessing data is ordering and downloading the products. By ordering a product, you are indicating that you intend to pay for access to the product. In cases where the data is free or you have previously ordered a product, you will not be charged. When products are ordered, the Capella system will begin preparing the products for access by ensuring they are available within a cloud storage bucket. Once the order has been prepared, you can submit a request to download the product, which will create signed URLs for the product assets. These signed URLs can be used to download or transfer the product to your environment or to access cloud optimized geotiffs directly from Capella's storage bucket.`}</p>
    <h2 {...{
      "id": "preparing-an-order"
    }}>{`Preparing an Order`}</h2>
    <p>{`To prepare an order, you will need to first create an array of the products you would like to order. You will need both the identifier for the product (`}<inlineCode parentName="p">{`granuleId`}</inlineCode>{`) and the identifier for the collection (`}<inlineCode parentName="p">{`collectionId`}</inlineCode>{`) that the product is part of. The identifiers can be found in the properties of each item returned from a search. In the search results, these properties are called `}<inlineCode parentName="p">{`id`}</inlineCode>{` and `}<inlineCode parentName="p">{`collection`}</inlineCode>{`. A properly formatted request body for an order will look like this:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
    "items": [
        {
          "collectionId": "capella-geo",
          "granuleId": "CAPELLA_C08_SM_GEO_HH_20220624141049_20220624141053"
        }
    ]
}
`}</code></pre>
    <h2 {...{
      "id": "reviewing-cost"
    }}>{`Reviewing Cost`}</h2>
    <p>{`If you would like to review the cost of an order before you submit, you can use the order review endpoint and request body described above.`}</p>
    <pre><code parentName="pre" {...{}}>{`POST https://api.capellaspace.com/orders/review
`}</code></pre>
    <p>{`This will return a summary of the total cost of the order as well as the cost of each individual product. This step is optional before placing an order.`}</p>
    <h2 {...{
      "id": "submitting-an-order"
    }}>{`Submitting an Order`}</h2>
    <p>{`To submit the order to the system, you will use the orders endpoint and the request body described above.`}</p>
    <pre><code parentName="pre" {...{}}>{`POST https://api.capellaspace.com/orders
`}</code></pre>
    <p>{`If an order is successfully submitted, a description of the order and the order status will be returned:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "userId": "4a6...",
  "organizationId": "623...",
  "orderDate": "2023-03-28T22:27:55.763Z",
  "expirationDate": "2023-03-28T23:27:55.763Z",
  "orderId": "955...",
  "orderStatus": "completed",
  "items": [
    {
      "collectionId": "capella-geo",
      "granuleId": "CAPELLA_C08_SM_GEO_HH_20220624141049_20220624141053",
      "previouslyOrdered": true,
      "type": "stripmap",
      "collectionDate": "2022-06-25T23:24:39.347Z"
    }
  ]
}
`}</code></pre>
    <p>{`You can see the status of the order in the `}<inlineCode parentName="p">{`orderStatus`}</inlineCode>{` property. Orders that have a status of `}<inlineCode parentName="p">{`completed`}</inlineCode>{` are ready for download. You will need the `}<inlineCode parentName="p">{`orderId`}</inlineCode>{` from the response to prepare the order for download.`}</p>
    <h2 {...{
      "id": "submitting-an-order-that-includes-a-single-image-analytic-product"
    }}>{`Submitting an Order that includes a single image Analytic product`}</h2>
    <p>{`Single image analytic products, such as Vessel Detection, can be created from any archive imagery. To include a Vessel Detection product to an archive imagery order, an additional block is added to the `}<inlineCode parentName="p">{`items`}</inlineCode>{` list in the request body with the `}<inlineCode parentName="p">{`productType`}</inlineCode>{` and a list of the `}<inlineCode parentName="p">{`collectIds`}</inlineCode>{` for which to create the analytic product. This request is sent to the `}<inlineCode parentName="p">{`POST /orders`}</inlineCode>{` API endpoint.`}</p>
    <pre><code parentName="pre" {...{}}>{`{
    "items": [
        {
          "collectionId": "capella-geo",
          "granuleId": "CAPELLA_C08_SM_GEO_HH_20220624141049_20220624141053"
        },
        {
          "productType": "VS",
          "collectIds": ["e290bca6-8a6c-4a0b-96bf-9174f8b5bccc"]
        }
    ]
}
`}</code></pre>
    <p>{`Note: a GEO image must be specified as part of the analytic order as this is the product used to derive Vessel Detection, and it must be ordered along with vessel detection.`}</p>
    <h2 {...{
      "id": "preparing-an-order-for-download"
    }}>{`Preparing an Order for Download`}</h2>
    <p>{`Preparing an order for download is simply a matter of assembling a request using the `}<inlineCode parentName="p">{`orderId`}</inlineCode>{` in the following way:`}</p>
    <pre><code parentName="pre" {...{}}>{`GET https://api.capellaspace.com/orders/{{orderId}}/download
`}</code></pre>
    <p>{`An array of items will be returned, similar to a search result, but the product asset descriptions will now include signed URLs to the assets in Capella's cloud storage bucket in the `}<inlineCode parentName="p">{`href`}</inlineCode>{` fields. You can use the signed URLs to access the product or transfer the product to your environment.`}</p>
    <h2 {...{
      "id": "automated-workflows"
    }}>{`Automated Workflows`}</h2>
    <p>{`A common workflow is to create an automated routine to query the status of recent task requests, identify task requests that are completed, create an order, and download the data for completed tasks. To encourage efficient API usage and reduce call volume, it is recommended to leverage bulk API operations such as the `}<inlineCode parentName="p">{`GET tasks/paged`}</inlineCode>{` endpoint for these workflows, as opposed to looping through a list of task requests and executing status queries by individual Task Request ID.`}</p>
    <p>{`For example, the following process can efficiently satisfy the workflow described above:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Fetch the 100 latest submitted tasking requests for a specific Organization ID`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`  POST tasks/paged?limit=100&page=1&organizationId=<ORGANIZATION_ID>
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Filter results by searching status history for 'completed' tasks`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`  properties.statusHistory.0.code == 'completed'
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`For each 'completed' tasking request`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`Order all assets of all STAC items of collects for tasking request`}</p>
            <pre parentName="li"><code parentName="pre" {...{}}>{`POST orders/task/{taskingRequestId}
`}</code></pre>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Create download assets`}</p>
            <pre parentName="li"><code parentName="pre" {...{}}>{`GET orders/{orderId}/download
`}</code></pre>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Download data using presigned URLs provided in the `}<inlineCode parentName="p">{`/download`}</inlineCode>{` response`}</p>
          </li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Repeat the above steps with results from the next page if necessary`}</p>
        <pre parentName="li"><code parentName="pre" {...{}}>{`  POST tasks/paged?limit=100&page=2&organizationId=<ORGANIZATION_ID>
`}</code></pre>
      </li>
    </ul>
    <p>{`NOTE: results of `}<inlineCode parentName="p">{`GET tasks/paged`}</inlineCode>{` are sorted by `}<inlineCode parentName="p">{`properties.submissionTime`}</inlineCode>{` which could be used as an early abort criterion (e.g. stop if latest result of page is older than date x.y.z)`}</p>
    <h2 {...{
      "id": "additional-information"
    }}>{`Additional Information`}</h2>
    <p>{`For more information on ordering and downloading data, please see the API reference for `}<a parentName="p" {...{
        "href": "https://docs.capellaspace.com/api/data-access"
      }}>{`Data Access`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      